import React from 'react';

import './Loader.scss'
import '../components/App.scss'

class Loader extends React.Component {

    render() {
        return (
            <div className='loader'></div>
        )
    }

}

export default Loader;