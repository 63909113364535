import React from 'react';

import './GamelogDetails.scss'
import './App.scss'
import GlobalContext from './GlobalContext';
import SegmentedControl from './SegmentedControl';
import Table from './Table';
import { getDictionaryValue, getGameResultText, getPlayerId, getPlayerName, getTeamGameResultText, parseDateFromString } from '../linemate-react-common/src/util';
import { getGamelogSupportingStats, getPlayerPropInfo, PLAYER_BET_TYPE_ID_TO_NAME, SCREENER_TEAM_SCORING_STATS, TEAM_BETS } from '../linemate-react-common/src/constants';
import { API_HOST } from '../react-web-constants';
import { buildTrendsData, getGameLineValueById } from '../linemate-react-common/src/betting-utils';
import { getTeamLogoPath, teamLogoPathOnErrorFallback } from '../react-web-utils';

const TREND_DETAILS_TYPE_PARLAY = "parlay";
const TREND_DETAILS_TYPE_STRAIGHT = "straight";

/**
 * Props:
 * - parlay|trend: {}
 * - without: {}?
 */
class GamelogDetails extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.getTableColumnHeaderTitle = this.getTableColumnHeaderTitle.bind(this);
        this.segmentChanged = this.segmentChanged.bind(this);

        this.type = 'parlay' in props ? TREND_DETAILS_TYPE_PARLAY : TREND_DETAILS_TYPE_STRAIGHT;
        this.segmentOptions = {
            'all': 'All',
            'LAST_10': 'Last 10',
            'MATCHUP': 'Head to Head'
        }

        if (this.type === TREND_DETAILS_TYPE_PARLAY) {
            // This only works as long as we have a parlay that is same-team
            if (props.parlay.legs[0].home) {
                this.segmentOptions['HOME_SPLITS'] = 'Home Splits'
            } else {
                this.segmentOptions['HOME_SPLITS'] = 'Home Splits'
            }
        } else if (this.type === TREND_DETAILS_TYPE_STRAIGHT) {
            if (props.trend.home) {
                this.segmentOptions['HOME_SPLITS'] = 'Home Splits'
            } else {
                this.segmentOptions['HOME_SPLITS'] = 'Home Splits'
            }
        }

        this.state = {
            segmentSelection: Object.keys(this.segmentOptions)[0],
            parlay: {
                initialGameRecords: {},
                filteredGameRecords: {}
            },
            straight: {
                initialGameRecords: [],
                filteredGameRecords: []
            }
        }
    }

    componentDidMount() {
        const currentSeason = this.context.configuration.metadata.leagues[this.context.league].currentSeason;
        if (this.type === TREND_DETAILS_TYPE_PARLAY) {
            // Set for unique values
            const playerIds = [...new Set(this.props.parlay.legs.filter(x => x.type === "player").map(x => getPlayerId(x.player)))]
            const teamCodes = [...new Set(this.props.parlay.legs.filter(x => x.type === "team").map(x => x.team.code))]
            fetch(`${API_HOST}/api/${this.context.league}/v1/games/with?playerIds=${playerIds.join(",")}&teamCodes=${teamCodes.join(",")}&sortingOrder=DESC&timeframe=RANGE_${currentSeason - 2}_${currentSeason}`)
            .then((result) => result.json())
            .then((result) => this.setState({
                loading: false,
                parlay: {
                    initialGameRecords: result,
                    filteredGameRecords: result
                }
            }))
            .catch((error) => {
                console.log(error)
                this.setState({loading: false})
            })
        } else if (this.type === TREND_DETAILS_TYPE_STRAIGHT) {
            var endpoint = "";
            if (this.props.trend.type === "player") {
                endpoint = `players/${getPlayerId(this.props.trend.player)}/games?sortingOrder=DESC&timeframe=RANGE_${currentSeason - 2}_${currentSeason}`
            } else if (this.props.trend.type === "team") {
                endpoint = `teams/${this.props.trend.team.code}/games?sortingOrder=DESC&timeframe=RANGE_${currentSeason - 2}_${currentSeason}`
            } else  {
                return;
            }
            if (this.props.without) {
                endpoint += `&withoutPlayerIds=${this.props.without.id}`
            }
            fetch(`${API_HOST}/api/${this.context.league}/v2/${endpoint}`)
            .then((result) => result.json())
            .then((result) => this.setState({
                loading: false,
                straight: {
                    initialGameRecords: result,
                    filteredGameRecords: result
                }
            }))
            .catch((error) => {
                console.log(error)
                this.setState({loading: false})
            })
        }
    }

    getTableColumnHeaderTitle(leg) {
        if (leg.type === 'player') {
            return `${getPlayerName(leg.player)} \u2022 ${PLAYER_BET_TYPE_ID_TO_NAME[this.context.league][leg.market.name]}`
        }
        return `${leg.team.code} \u2022 ${TEAM_BETS[this.context.league][leg.market.name].displayName}`;
    }

    getLegDataKey(leg) {
        return leg.type === "player" ? getPlayerId(leg.player) : leg.team.code;
    }

    filterParlayGameRecords(initialGameRecords, segmentSelection, team1, team2) {
        if (segmentSelection === 'all') {
            return initialGameRecords;
        }
        const filteredGameRecords = {};
        Object.keys(initialGameRecords).forEach((key) => {
            if (segmentSelection === 'LAST_10') {
                filteredGameRecords[key] = initialGameRecords[key].slice(0, 10)
            }
            if (segmentSelection === 'MATCHUP') {
                filteredGameRecords[key] = initialGameRecords[key].filter(x => (x.currentTeam === team1 && x.opposingTeam === team2) || (x.currentTeam === team2 && x.opposingTeam === team1))
            }
            if (segmentSelection === 'HOME_SPLITS') {
                filteredGameRecords[key] = initialGameRecords[key].filter(x => x.home)
            }
            if (segmentSelection === 'AWAY_SPLITS') {
                filteredGameRecords[key] = initialGameRecords[key].filter(x => !x.home)
            }
        })
        return filteredGameRecords;
    }

    filterStraightGameRecords(initialGameRecords, segmentSelection, opposingTeam) {
        if (segmentSelection === 'all') {
            return initialGameRecords;
        }
        if (segmentSelection === 'LAST_10') {
            return initialGameRecords.slice(0, 10)
        }
        if (segmentSelection === 'MATCHUP') {
            return initialGameRecords.filter(x => x.opposingTeam === opposingTeam)
        }
        if (segmentSelection === 'HOME_SPLITS') {
            return initialGameRecords.filter(x => x.home)
        }
        if (segmentSelection === 'AWAY_SPLITS') {
            return initialGameRecords.filter(x => !x.home)
        }
    }

    segmentChanged(selection) {
        if (this.state.segmentSelection === selection) {
            return;
        }
        if (this.type === TREND_DETAILS_TYPE_PARLAY) {
            this.setState({
                segmentSelection: selection,
                parlay: {
                    initialGameRecords: this.state.parlay.initialGameRecords,
                    filteredGameRecords: this.filterParlayGameRecords(this.state.parlay.initialGameRecords, selection, this.props.parlay.homeTeam.code, this.props.parlay.awayTeam.code)
                }
            })
        } else if (this.type === TREND_DETAILS_TYPE_STRAIGHT) {
            this.setState({
                segmentSelection: selection,
                straight: {
                    initialGameRecords: this.state.straight.initialGameRecords,
                    filteredGameRecords: this.filterStraightGameRecords(this.state.straight.initialGameRecords, selection, this.props.trend.opposingTeam.code)
                }
            })
        }
    }

    render() {
        var tableHeaderText = "";
        var dataColumnHeaders = []
        // Straight-only
        var statColumns = {}
        if (this.type === TREND_DETAILS_TYPE_PARLAY) {
            tableHeaderText = "Games played together"
            dataColumnHeaders = this.props.parlay.legs.map(x => this.getTableColumnHeaderTitle(x).toUpperCase())
        } else if (this.type === TREND_DETAILS_TYPE_STRAIGHT) {
            tableHeaderText = "Games played"
            if (this.props.trend.type === "player") {
                const playerPropInfo = getPlayerPropInfo[this.context.league](this.props.trend.market.name, null);
                statColumns = getGamelogSupportingStats[this.context.league](playerPropInfo)
                dataColumnHeaders = Object.keys(statColumns);
            } else if (this.props.trend.type === "team") {
                statColumns = SCREENER_TEAM_SCORING_STATS(this.context.league, "cumulativeStats", "offensive")
                dataColumnHeaders = Object.keys(statColumns)
            }
            if (this.props.without) {
                tableHeaderText += ` without ${this.props.without.name}`
            }
        }
        return (
            <div className='parlay-workstation-wrapper'>
                <div className='parlay-workstation-header'>
                    <p className='text-style-h-3-medium color-fig-default'>{tableHeaderText}</p>
                    <div className='parlay-workstation-header-segment-control-desktop'>
                        <SegmentedControl options={this.segmentOptions} selection={this.state.segmentSelection} selectionHandler={this.segmentChanged}/>
                    </div>
                    <div className='parlay-workstation-header-segment-control-mobile'>
                        <SegmentedControl options={this.segmentOptions} selection={this.state.segmentSelection} selectionHandler={this.segmentChanged} fillWidth={true}/>
                    </div>
                </div>
                <div className='parlay-workstation-content'>
                    {
                        this.type === TREND_DETAILS_TYPE_PARLAY && (
                            <Table 
                                className='trends-page-game-log-table-mobile'
                                columns={['DATE', 'OPPONENT', 'RESULT', 'PARLAY HIT'].concat(dataColumnHeaders)}
                                rowCount={Object.values(this.state.parlay.filteredGameRecords).length > 0 ? Object.values(this.state.parlay.filteredGameRecords)[0].length : 0}
                                columnStyling={(columnIndex, columnName) => {
                                    if (columnIndex === 0) {
                                        return {width: 104, textAlign: 'left', padding: '0px 12px'}
                                    }
                                    if (columnIndex === 1) {
                                        return {width: 158, textAlign: 'left', padding: '0px 8px'};
                                    }
                                    if (columnIndex === 2) {
                                        return {width: 158, textAlign: 'left', padding: '0px 8px'};
                                    }
                                    if (columnIndex === 3) {
                                        return {width: 158, textAlign: 'center', padding: '0px 8px'};
                                    }
                                    return {textAlign: 'right', padding: '0px 12px'}
                                }}
                                dataExtractor={(rowIndex, columnIndex, columnName) => {
                                    // The data for the first few columns should be the same regardless of who's game record we use
                                    const genericRecord = Object.values(this.state.parlay.filteredGameRecords)[0][rowIndex]
                                    if (columnIndex === 0) {
                                        const gameDate = parseDateFromString(genericRecord.timestamp);
                                        return `${String(gameDate.month).padStart(2, '0')}/${String(gameDate.day).padStart(2, '0')}/${gameDate.yearShort}`
                                    }
                                    if (columnIndex === 1) {
                                        const teamCode = genericRecord.opposingTeam;
                                        return (
                                            // Wrapping the content of the cell in a div allows us to then use flex inside
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                                                <img src={getTeamLogoPath(this.context.league, teamCode)} onError={({currentTarget}) => teamLogoPathOnErrorFallback(currentTarget, this.context.league)} height={32} width={32} alt={teamCode}/>
                                                <p className="text-style-label-medium">{teamCode}</p>
                                            </div>
                                        )
                                    }
                                    if (columnIndex === 2) {
                                        // Only the player game record has 'info'
                                        return 'info' in genericRecord ? getGameResultText(this.context.league, genericRecord) : getTeamGameResultText(this.context.league, genericRecord);
                                    }
                                    if (columnIndex === 3) {
                                        const parlayHit = this.props.parlay.legs.map(x => buildTrendsData(this.context.league, x.type, [this.state.parlay.filteredGameRecords[this.getLegDataKey(x)][rowIndex]], null, x.line, x.market.name)).every(x => x.hits === 1)
                                        const assetName = parlayHit ? 'check-circle' : 'cancel-circle'
                                        return (
                                            <img className='parlay-workstation-content-parlay-column' data-hit={parlayHit} src={`assets/${assetName}.svg`} alt={assetName}/>
                                        )
                                    }
                                    // If we change the static column layout the number we subtract may have to change
                                    const leg = this.props.parlay.legs[columnIndex - 4]
                                    const dataKey = this.getLegDataKey(leg);
                                    const participantRecord = this.state.parlay.filteredGameRecords[dataKey][rowIndex]
                                    return getGameLineValueById(this.context.league, leg.type, participantRecord, leg.market.name)
                                }}
                            />
                        )
                    }
                    {
                        this.type === TREND_DETAILS_TYPE_STRAIGHT && (
                            <Table 
                                className='trends-page-game-log-table-mobile'
                                columns={['DATE', 'OPPONENT', 'RESULT', 'HIT'].concat(dataColumnHeaders)}
                                rowCount={this.state.straight.filteredGameRecords.length}
                                columnStyling={(columnIndex, columnName) => {
                                    if (columnIndex === 0) {
                                        return {width: 104, textAlign: 'left', padding: '0px 12px'}
                                    }
                                    if (columnIndex === 1) {
                                        return {width: 158, textAlign: 'left', padding: '0px 8px'};
                                    }
                                    if (columnIndex === 2) {
                                        return {width: 104, textAlign: 'left', padding: '0px 8px'};
                                    }
                                    if (columnIndex === 3) {
                                        return {width: 104, textAlign: 'center', padding: '0px 8px'};
                                    }
                                    return {textAlign: 'right', padding: '0px 12px'}
                                }}
                                dataExtractor={(rowIndex, columnIndex, columnName) => {
                                    // The data for the first few columns should be the same regardless of who's game record we use
                                    const record = this.state.straight.filteredGameRecords[rowIndex]
                                    if (columnIndex === 0) {
                                        const gameDate = parseDateFromString(record.timestamp);
                                        return `${String(gameDate.month).padStart(2, '0')}/${String(gameDate.day).padStart(2, '0')}/${gameDate.yearShort}`
                                    }
                                    if (columnIndex === 1) {
                                        const teamCode = record.opposingTeam;
                                        return (
                                            // Wrapping the content of the cell in a div allows us to then use flex inside
                                            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 8}}>
                                                <img src={getTeamLogoPath(this.context.league, teamCode)} onError={({currentTarget}) => teamLogoPathOnErrorFallback(currentTarget, this.context.league)} height={32} width={32} alt={teamCode}/>
                                                <p className="text-style-label-medium">{teamCode}</p>
                                            </div>
                                        )
                                    }
                                    if (columnIndex === 2) {
                                        // Only the player game record has 'info'
                                        return 'info' in record ? getGameResultText(this.context.league, record) : getTeamGameResultText(this.context.league, record);
                                    }
                                    if (columnIndex === 3) {
                                        const trendData = buildTrendsData(this.context.league, this.props.trend.type, [record], null, this.props.trend.line, this.props.trend.market.name)
                                        const hit = this.props.trend.outcome === "under" ? trendData.hits === 0 && trendData.push === 0 : trendData.hits === 1
                                        const assetName = hit ? 'check-circle' : 'cancel-circle'
                                        return (
                                            <img className='parlay-workstation-content-parlay-column' data-hit={hit} src={`assets/${assetName}.svg`} alt={assetName}/>
                                        )
                                    }
                                    return getDictionaryValue(record, statColumns[columnName]);
                                }}
                            />
                        )
                    }
                </div>
            </div>
        );
    }
}

export default GamelogDetails;