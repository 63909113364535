import { orderListByField, getPreferredFormat, getBookEntryBasedOnPrecedence, getFormattedLineValue, getFormattedOddValue, isWeeklyCompetition } from './util.js';

// As of version 1.6.0, data for soccer is going to be requested on a weekly basis
// Once there are enough devices that have updated to 1.6.0 or further we can remove that parameter from the API and from the app, making it the default option
export function getGamedaySchedule(host, league, recordType, reduced) {
  return [
    getCurrentGamedaySchedule(host, league, recordType, reduced),
    fetch(`${host}/api/${league}/v2/games/upcoming?recordType=${recordType}`).then(data => data.json())
  ]
}

export function getCurrentGamedaySchedule(host, league, recordType, reduced) {
  return fetch(`${host}/api/${league}/v2/games/current?recordType=${recordType}`).then(data => data.json());
}

// This could probably go out of the gameday specifically
export function getAllTeams(host, league) {
  return fetch(`${host}/api/${league}/v2/teams`).then(data => data.json());
}

export function buildStandings(teams) {
  var standings = {};

  if ("division" in teams[0].standings && teams[0].standings.division !== null) {
    var divisionalRankings = {};
    // Build the grouping of divisions
    for (var team of teams) {
      const division = team.standings.division;
      if (!(division in divisionalRankings)) {
        divisionalRankings[division] = [];
      }
      divisionalRankings[division].push(team);
    }
    // Sort the divisions based on the divisionalRank
    for (var division in divisionalRankings) {
      orderListByField(divisionalRankings[division], "standings.divisionRank", "asc");
    };
    standings["division"] = divisionalRankings;
  }
  if ("conference" in teams[0].standings && teams[0].standings.conference !== null) {
    var conferenceRankings = {};
    // Build the grouping of conferences
    for (var conferenceTeam of teams) {
      const conference = conferenceTeam.standings.conference;
      if (!(conference in conferenceRankings)) {
        conferenceRankings[conference] = [];
      }
      conferenceRankings[conference].push(conferenceTeam);
    }
    // Sort the conferences based on the conferenceRank
    for (var conference in conferenceRankings) {
      orderListByField(conferenceRankings[conference], "standings.conferenceRank", "asc");
    }
    standings["conference"] = conferenceRankings;
  } 
  if ("league" in teams[0].standings && teams[0].standings.league !== null) {
    // This is for MLB
    var leagueRankings = {};
    // Build the grouping of leagues
    for (var leagueTeam of teams) {
      const league = leagueTeam.standings.league;
      if (!(league in leagueRankings)) {
        leagueRankings[league] = [];
      }
      leagueRankings[league].push(leagueTeam);
    }
    // Sort the leagues based on the leagueRank
    for (var league in leagueRankings) {
      orderListByField(leagueRankings[league], "standings.leagueRank", "asc");
    }
    standings["league"] = leagueRankings;
  } 
  
  // TODO: review if doing this without creating a new list screws up any existing one (mainly for MLB right now)  
  if (Object.keys(standings).length < 2) {
    if ("overallRank" in teams[0].standings) {
      orderListByField(teams, "standings.overallRank", "asc");
      standings["overall"] = {"Overall": teams};
    } else if ("rank" in teams[0].standings) {
      orderListByField(teams, "standings.rank", "asc");
      standings["overall"] = {"Overall": teams};
    }
  }
  return standings;
}

// shortDescription -> boolean to indicate whether or not to use the shorter version of bets/lines, such as o/u versus over/under
export function getGameMarketInfo(game, userAttributes, shortDescription) {
  const lineFormat = getPreferredFormat(userAttributes);
  const gameMarkets = game.markets || {};
  const gameTotalMarket = gameMarkets["GAME_TOTAL"] || {};
  const gameTotalBook = getBookEntryBasedOnPrecedence(gameTotalMarket.books);

  const defaultMarketText = "--";
  var totalOverLineText = defaultMarketText;
  var totalOverOddsText = defaultMarketText;
  var totalUnderLineText = defaultMarketText;
  var totalUnderOddsText = defaultMarketText;
  // TODO extract this as function to do it based on outcome as a utility for over/under + value
  //  We could also extract a function for cover and cover + value
  if (gameTotalBook && gameTotalBook.over && gameTotalBook.over.current) {
    if (gameTotalBook.over.current.value) {
      totalOverLineText = `${shortDescription ? "o" : "Over "}${gameTotalBook.over.current.value}`
    }
    if (gameTotalBook.over.current.odds) {
      totalOverOddsText = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, gameTotalBook.over.current.odds));
    }
  }
  if (gameTotalBook && gameTotalBook.under && gameTotalBook.under.current) {
    if (gameTotalBook.under.current.value) {
      totalUnderLineText = `${shortDescription ? "u" : "Under "}${gameTotalBook.under.current.value}`
    }
    if (gameTotalBook.under.current.odds) {
      totalUnderOddsText = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, gameTotalBook.under.current.odds));
    }
  }
  return {
    awayTeamData: {
      line: totalOverLineText,
      odds: totalOverOddsText
    },
    homeTeamData: {
      line: totalUnderLineText,
      odds: totalUnderOddsText
    }
  }
}

export function getTeamMarketInfo(teamSplit, userAttributes) {
  const lineFormat = getPreferredFormat(userAttributes);
  const teamMarkets = teamSplit.markets || {};
  const moneyMarket = teamMarkets["MONEY_LINE"] || {};
  const moneyBook = getBookEntryBasedOnPrecedence(moneyMarket.books);

  const defaultMarketText = "--";
  var moneyText = defaultMarketText;
  if (moneyBook && moneyBook.cover && moneyBook.cover.current && moneyBook.cover.current.odds) {
    moneyText = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, moneyBook.cover.current.odds));
  }

  const spreadMarket = teamMarkets["HANDICAP"] || teamMarkets["SPREAD"] || {};
  const spreadBook = getBookEntryBasedOnPrecedence(spreadMarket.books);
  var spreadLineText = defaultMarketText;
  var spreadOddsText = defaultMarketText;
  if (spreadBook && spreadBook.cover && spreadBook.cover.current) {
    if (spreadBook.cover.current.value) {
      spreadLineText = `${spreadBook.cover.current.value > 0 ? "+" : ""}${spreadBook.cover.current.value}`
    }
    if (spreadBook.cover.current.odds) {
      spreadOddsText = getFormattedLineValue(lineFormat, getFormattedOddValue(userAttributes, spreadBook.cover.current.odds));
    }
  }
  return {
    spread: {
      line: spreadLineText,
      odds: spreadOddsText
    },
    moneyline: moneyText
  }
}

export const CURRENT_MATCHUPS = 'current';
export const UPCOMING_MATCHUPS = 'upcoming';