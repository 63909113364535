import React from 'react';
import { getTheme, toggleTheme } from '../react-web-utils';
import { SUPPORTED_LEAGUES_METADATA } from '../linemate-react-common/src/constants';
import { mapDictionary } from '../linemate-react-common/src/core-utils';
import { hasPremiumAccess, isDictEmpty, isSuperUser } from '../linemate-react-common/src/util';
import { auth } from '../firebase.js'

import Button from './Button';
import Avatar from './Avatar';
import InputSelection from './InputSelection';
import GlobalContext from './GlobalContext';

import './App.scss';
import './NavigationBar.scss';
import Dialog from './Dialog';
import AffiliatesList from './AffiliatesList';
import SearchResult from './SearchResult';

// TODO: for 'start free trial', go to signup with origin as pricing
class NavigationBar extends React.Component {

    static contextType = GlobalContext;

    constructor(props) {
        super(props);

        this.toggleTheme = this.toggleTheme.bind(this);
        this.toggleMobileNavigation = this.toggleMobileNavigation.bind(this);
        this.getControls = this.getControls.bind(this);
        this.getTabs = this.getTabs.bind(this);
        this.leagueDropdownSelected = this.leagueDropdownSelected.bind(this);
        this.searchPressed = this.searchPressed.bind(this);

        this.avatarMenuItems = {
            'Profile settings': () => window.location.href = '/profile', 
            // Note: the anonymous function is needed rather than a direct auth.signOut reference otherwise it won't work because of a mismatch in number of arguments
            'Log Out': () => auth.signOut()
        }

        var searchActive = false;
        var presetSearchTerm = null;
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('search')) {
            searchActive = true;
        }
        if (searchParams.get('search')) {
            presetSearchTerm = searchParams.get('search')
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        }

        this.state = {
            theme: getTheme(),
            mobileNavigationOpen: false,
            affiliatesDrawerOpen: false,
            searchActive: searchActive,
            searchTerm: presetSearchTerm
        }
    }

    componentDidMount() {
        const leagueActive = !this.context.league || this.context.configuration.metadata.leagues[this.context.league].active;
        var navbarHeight = 48;
        if (!leagueActive) {
            navbarHeight += 36;
        }
        document.documentElement.style.setProperty("--navbar-height", `${navbarHeight}px`);
    }

    toggleTheme() {
        this.setState({theme: toggleTheme()})
    }

    toggleMobileNavigation() {
        this.setState((previousState) => {return {mobileNavigationOpen: !previousState.mobileNavigationOpen}})
    }

    getControls() {
        return (
            <>
                <div className='navigation-bar-left-dropdown-trigger'>
                    <InputSelection enabled={true} type="single" typography="sm" options={mapDictionary(SUPPORTED_LEAGUES_METADATA, (value) => value.title)} selection={this.context.league || this.context.configuration.metadata.defaultLeague} selectionHandler={this.leagueDropdownSelected}/>
                </div>
                <div className='navigation-bar-left-search'>
                    <Button icon="assets/search.svg" type="secondary" enabled={true} onClick={this.searchPressed}/>
                </div>
            </>
        )
    }

    getTabs() {
        return (
            <>
                <div className={`navigation-bar-tab${this.context.tab === "home" ? "-selected" : ""}`}>
                    <a className='text-style-label-medium' href={`/${this.context.league || this.context.configuration.metadata.defaultLeague}`}>Home</a>
                </div>
                <div className={`navigation-bar-tab${this.context.tab === "trends" ? "-selected" : ""}`}>
                    <a className='text-style-label-medium' href={`/${this.context.league || this.context.configuration.metadata.defaultLeague}/trends`}>Trends</a>
                </div>
                {
                    isSuperUser(this.context.userAttributes) && (
                        <div className={`navigation-bar-tab${this.context.tab === "socials" ? "-selected" : ""}`}>
                            <a className='text-style-label-medium' href={`/${this.context.league || this.context.configuration.metadata.defaultLeague}/socials`}>Socials</a>
                        </div>
                    )
                }
            </>
        )
    }

    leagueDropdownSelected(selection) {
        if (this.context.league) {
            window.location.href = window.location.pathname.replace(this.context.league, selection)   
        } else {
            window.location.href = `/${selection}`   
        }
    }

    searchPressed() {
        this.setState((previousState) => {
            if (previousState.searchActive) {
                document.getElementsByTagName('body')[0].style.overflow = 'auto';
            } else {
                document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            }
            return {searchActive: !previousState.searchActive, mobileNavigationOpen: false}
        })
    }

    // TODO: should we avoid rendering the league selection dropdown if it's not a 'sport data' page?
    render() {
        const leagueActive = !this.context.league || this.context.configuration.metadata.leagues[this.context.league].active;
        const hasAffiliates = this.context.location.region.code && this.context.location.region.code in this.context.configuration.metadata.sportsbookAffiliates;
        const loggedIn = this.context.user && !isDictEmpty(this.context.userAttributes);
        const premiumUser = hasPremiumAccess(this.context.userAttributes)
        return (
            <>
                {/* Container under the absolutely positioned header to push content down */}
                <div className='navigation-bar-placeholder'></div>
                <div className={`navigation-bar ${this.state.affiliatesDrawerOpen ? "navigation-bar-extra-z-index" : ""}`}>
                    {/* Left section: logo, league selection, search */}
                    <div className='navigation-bar-left'>
                        <a className="navigation-bar-wordmark" href="/">
                            <img src="assets/logomark.svg" alt="Linemate"/>
                        </a>
                        {this.getControls()}
                    </div>
                    {/* Middle section: tabs/pages */}
                    <div className='navigation-bar-middle'>
                        {this.getTabs()}
                    </div>
                    {/* Right section: LM+, Profile, Login/Signup, etc. */}
                    <div className='navigation-bar-right'>
                        {
                            loggedIn && !premiumUser && (
                                <div className='navigation-bar-right-lm-plus-button-wrapper'>
                                    <Button text="Get Linemate+" typography="sm" type="primary" enabled={true} onClick={() => window.location.href = `/pricing?origin=${window.location.pathname}`}/>
                                </div>
                            )
                        }
                        {
                            !loggedIn && (
                                <>
                                    <div className='navigation-bar-right-login-button-wrapper'>
                                        <Button text="Log in" typography="sm" type="secondary" enabled={true} onClick={() => window.location.href = `/login?origin=${window.location.pathname}`}/>
                                    </div>
                                    <div className='navigation-bar-right-signup-button-wrapper'>
                                        <Button text="Start free trial" typography="sm" type="primary" enabled={true} onClick={() => window.location.href = `/signup?origin=/pricing`}/>
                                    </div>
                                </>
                            )
                        }
                        {/* On the home tab we're always going to show the affiliates so we don't show the option to toggle this */}
                        {/* In case where we allow toggle, only show when affiliates are available */}
                        {
                            this.context.tab !== "home" && hasAffiliates && (
                                <div className='navigation-bar-right-moneybag-button-wrapper'>
                                    <Dialog
                                        className='navigation-bar-affiliates-drawer'
                                        open={this.state.affiliatesDrawerOpen}
                                        onOpenChange={(open) => this.setState({affiliatesDrawerOpen: open})}
                                        trigger={
                                            <Button icon="assets/moneybag.svg" type="invisible" enabled={true} onClick={() => this.setState({affiliatesDrawerOpen: true})}/>
                                        }
                                        title={null}
                                        content={<AffiliatesList onClose={() => this.setState({affiliatesDrawerOpen: false})}/>}
                                        footer={null}
                                    />
                                </div>
                            )
                        }
                        <div className='navigation-bar-right-theme-button-wrapper'>
                            <Button icon={`assets/theme-${this.state.theme}.svg`} type="invisible" enabled={true} onClick={this.toggleTheme}/>
                        </div>
                        <div className='navigation-bar-right-mobile-menu-button-wrapper'>
                            <Button icon={`assets/${this.state.mobileNavigationOpen ? "close-icon" : "menu"}.svg`} type="invisible" enabled={true} onClick={this.toggleMobileNavigation}/>
                        </div>
                        {
                            loggedIn && (
                                <div className='navigation-bar-right-avatar-wrapper'>
                                    <Avatar text={!('firstName' in this.context.userAttributes) ? "?" : this.context.userAttributes.firstName.charAt(0).toUpperCase()} typography="sm" clickable={true} menuItems={this.avatarMenuItems}/>
                                </div>
                            )
                        }
                    </div>
                </div>
                {
                    this.state.mobileNavigationOpen && (
                        <div className='navigation-bar-expansion'>
                            <div className='navigation-bar-expansion-controls'>
                                {this.getControls()}
                                <div className='navigation-bar-expansion-theme-button-wrapper'>
                                    <Button icon={`assets/theme-${this.state.theme}.svg`} type="invisible" enabled={true} onClick={this.toggleTheme}/>
                                </div>
                            </div>
                            <div className='navigation-bar-expansion-tabs'>
                                {this.getTabs()}
                            </div>
                            {/* For now we haven't decided what to do in terms of login enforcing or not. If user is not logged in won't show anything until then */}
                            {
                                this.context.user && this.context.userAttributes && (
                                    <div className='navigation-bar-expansion-account'>
                                        <div className='navigation-bar-expansion-account-username'>
                                            <p className='text-style-label-medium'>{this.context.userAttributes.username}</p>
                                        </div>
                                        <div className='navigation-bar-expansion-account-profile'>
                                            <a className='text-style-label-medium' href='/profile'>Profile settings</a>
                                        </div>
                                        <div className='navigation-bar-expansion-account-signout' onClick={() => auth.signOut()}>
                                            <p className='text-style-label-medium'>Log out</p>
                                        </div>
                                    </div>
                                )
                            }
                            {
                                !this.context.user && (
                                    <div className='navigation-bar-expansion-login-button-wrapper'>
                                        <Button text="Log in" typography="lg" type="secondary" enabled={true} onClick={() => window.location.href = `/login?origin=${window.location.pathname}`}/>
                                    </div>
                                )
                            }
                        </div>
                    )
                }
                {/* Optional banner under the navbar */}
                {
                    !leagueActive && (
                        <div className={`navigation-bar-banner ${this.state.affiliatesDrawerOpen ? "navigation-bar-extra-z-index" : ""}`}>
                            <img src='assets/vacation.svg' alt="vacation"/>
                            <p className='text-style-label-normal'>{`${SUPPORTED_LEAGUES_METADATA[this.context.league].title} off-season. See you next season.`}</p>
                        </div>
                    )
                }
                {
                    this.state.searchActive && (
                        <div className='navigation-bar-search'>
                            <SearchResult onBack={this.searchPressed} searchTerm={this.state.searchTerm}/>
                        </div>
                    )
                }
            </>
        )
    }
}

export default NavigationBar;